$(document).ready(function(){

	// initSubscribeFocus();
	testScrollDirection();
	initWaypoints();
    // initTop50StoryView();
	heroParallax();
	initVideoModal();
	initNewsFilter();
	initToggleMenu();
	initFeatureGallery();
	initFeatureCarousel();
	slick_init(slick_sliders);
	initMobMenu();
	initstickyHeader();
	initSmoothScroll();
	initSearchPanel();
    initSubscriptionForm();
    initContactForm();
    initNewsVideosLoadMore();
    initSocial();
	initSticky50Nav();
	initBackgroundVideo();
	megaMenu();
	setBgVideoSize();



    //loadStepinImg();

	$(window).scroll(function() {
		initstickyHeader();
	});

	$('.outer-container').scroll(function() {
		heroParallax();
	});

	$(window).resize(function() {
		// initNewsFilter();
		slick_init(slick_sliders);
		initstickyHeader();
		testScrollDirection();
	});

	// $('.video-modal-trigger').modaal({
	// 	type: 'video'
	// });


    // Google Maps stuff
    $('.acf-map').each(function(){
        var map = initMap( $(this) );
    });


	// touch style toggle
	$('body').find('a:not(.map-point), button').on('touchstart', function() {
		$(this).addClass('on-press');
	}).on('touchend', function(){
		$(this).removeClass('on-press');
	});

    let isClicked = false;

	$(document).on("click", function (e) {
		var target = e.target;

        if (!$(target).is("#feature-popup") && !$(target).is("a[href*='whats-on']")) {
            $(".is-open").removeClass("is-open");
            $(".disable-scroll").removeClass("disable-scroll");
            isClicked = false;
        }
	});

    $( ".menu-item a[href*='whats-on']" )
    .on( "mouseenter", function() {
        if (!$("#feature-popup").hasClass("is-open") && isClicked === false) {
            $("#feature-popup").addClass("is-open");
        }
    } );

	$( "#feature-popup" )
    .on( "mouseleave", function() {
		$(this).removeClass("is-open");
    } );

    $( ".menu-item a:not([href*='whats-on'])" )
    .on( "mouseenter", function() {
        if ($("#feature-popup").hasClass("is-open") && isClicked === false) {
            $("#feature-popup").removeClass("is-open");
        }
    } );


    $(".menu-item a[href*='whats-on'], .banner--home a[href*='whats-on']").click(function(e){
        e.preventDefault();

        if ($("#feature-popup").hasClass("is-open") && isClicked === true) {
			$("#feature-popup").removeClass("is-open");
            $(".outer-container").removeClass("disable-scroll");
            isClicked = false;
            $("a[href*='whats-on']").blur();
		}
        else{
            $("#feature-popup").addClass("is-open");
            $(".outer-container").addClass("disable-scroll");
            isClicked = true;
        }
        if ($('body').hasClass("menu-open")) {
            $('body').removeClass("menu-open");
        }
    });

    // $(".mobile-close").click(function(e){
    //     $(".is-open").removeClass("is-open");
    //     $(".disable-scroll").removeClass("disable-scroll");
    //     isClicked = false;
    // });

	// Wine Awards
	/*
	$('.btn--scroll').on('click', function(e) {
		e.preventDefault();

		var target = $(this).attr('href');
		var offset = $(target).offset().top - 100;

		$('body').animate({
			scrollTop: offset
		}, 1000, 'easeInCubic');
	});*/


    var $prevButt = document.querySelector('.slick-prev'),
        $nextButt = document.querySelector('.slick-next'),
        $dockButt = document.querySelector('.slider-button-dock');
    if ($prevButt) {
        $prevButt.textContent = '<';
        $nextButt.textContent = '>';
        $dockButt.appendChild($prevButt);
        $dockButt.appendChild($nextButt);
    }

});

// Parallax
// ------------------------------------------------

function heroParallax() {

	// controlable elems
	var openingPanel = $('.banner .banner__content.banner__content--animate');
	var openingPanelInner = $('.banner .banner__img, .news-feature__img');
	var scrollTarget = $('.outer-container'); 

	if ( (window.matchMedia('(min-width: 901px)').matches) && (!$('.tablet').length)  && ($('.has-parallax').length) ) {

		var x = scrollTarget.scrollTop();

		// parallax background
		if( scrollTarget.scrollTop() <= scrollTarget.height() ) {
			openingPanelInner.css({
				'transform': 'translateY('+x/6+'px)'
			});
			openingPanel.css({
				'transform': 'translateY('+x/4+'px)',
				'opacity': 1 - (x/700)
			});
		}

	} else {
		//openingPanel.css({'transform': 'translateY(0)', 'opacity': '1'});
		//openingPanelInner.css({'transform': 'translateY(0)'});
	}

}


function setBgVideoSize() {

	$('.banner__img > iframe').each(function() {

		var mw = Math.max(16/9 * this.parentNode.clientHeight, this.parentNode.clientWidth);

		this.style.minWidth = mw + 'px';
		this.style.height	= (9/16 * mw) + 'px';
		this.style.opacity	= 1;

	});

}
window.addEventListener('resize', setBgVideoSize, { passive : true });


function initBackgroundVideo() {

	$bg = $('[data-bg-video]');

	if($bg.length) {

		var js = document.createElement('script');
		js.src = "https://www.youtube.com/iframe_api";

		document.head.appendChild(js);

		window.onYouTubeIframeAPIReady = function() {

			$bg.each(function() {
				
				var args	= JSON.parse(this.dataset.bgVideo),
					parent	= this.parentNode,
					player	= new YT.Player(this, {
					width		: 16/9 * parent.clientHeight,
					height		: parent.clientHeight,
					videoId		: args.v,
					playerVars	: {
						modestbranding	: 1,
						playsinline		: 1,
						controls 		: 0,
						autoplay		: 1,
						loop			: 1
					},
					events		: {
						onReady			: () => {

							player.mute();
							player.playVideo();

						},
						onStateChange	: ({data}) => {
							
							if(data === YT.PlayerState.ENDED) {

								player.seekTo(0);
								player.playVideo();

							}

						}
					}
				});

				window.addEventListener('resize', () => void player.setSize(16/9 * parent.clientHeight, parent.clientHeight), { passive : true });

			});

		}

	}

}


function initFeatureCarousel() {

	var $carousel = $('.card--feature-carousel');

	function setCarouselMaxWidth() {

		$carousel.each(function() {

			this.style.setProperty('--slide-mw', $carousel.width() + 'px');

		});

	}

	$carousel.on('init', () => {

		$carousel.find('[data-group]').modaal({
			type : 'image'
		});

	});

	window.addEventListener('resize', setCarouselMaxWidth);
	
	setCarouselMaxWidth();

}


function initFeatureGallery() {

	$('.card--feature-gallery > [data-group]').modaal({
		type: 'image'
	});

}


// Set up waypoints
// ------------------------------------------------

function initWaypoints() {

	$('.waypoint').each(function(i) {

		var self = $(this);
		
		var reveal_offset = self.attr('data-offset') ? self.attr('data-offset') + '%' : '90%';
					
		var waypoint = self.waypoint({

			handler: function() {
				self.addClass('waypoint-reveal');
				
				// stuff has fired once, now let's destroy it
				this.destroy();
			},
			offset: reveal_offset,
            context: '.outer-container'
		})
	});
}

/**
 * Load the required functionality for the Top 50 Story view
 *
 */
function initTop50StoryView() {

    if (!$('.page--50-story').length) {

        return;
    }

    /**
     * Skip to Restaurant card target and offset to take into account fixed menu
     *
     */
    $('.top50-nav__anchor a').on('click', function(){

        var target_id = this.hash;

        $('.outer-container').scrollTop( $(target_id).offset().top - 300 +$('.outer-container').scrollTop() );

        return false;
    });

    $('.outer-container').scroll(function(){

        checkForImageLoadingRequired();
    });

    checkForImageLoadingRequired();
}

/**
 * Checks to see if the image position is within the viewport (or around it), then loads the image
 *
 */
function checkForImageLoadingRequired() {

    $('.img-loading:not(.img-loaded)').each(function(){

        var pos = $(this).offset().top;
        var window_height = $(window).height();

        if (pos > -(window_height * 2) && pos < (window_height * 3)) {

            var src = $(this).attr('data-img_src');

            $(this).addClass('img-loaded').css({
                backgroundImage: "url('" + src + "')"
            });
        }
    });
}

// Sticky top 50 nav thing
// ------------------------------------------------

function initSticky50Nav() {

    if ($('.page--50').length) {

        applySticky50Nav();

        $('.outer-container').scroll(function(){

            applySticky50Nav();
        });

        $(window).resize(function(){

            applySticky50Nav();
        });
    }
}

function applySticky50Nav() {

	//var trigger_height = $('header').outerHeight() + $('.banner').outerHeight() - parseInt($('.outer-container').css('padding-top'));
	
	var offset = 80;
	
	// if  ( ($('.header--show-alt').length) || (!$('.tablet').length) ) {
	
	if  ( ($('.header--show-alt').length) ) {
		offset = 140;
	}

	if ($('.tablet').length) {
		offset = 140;
	}
	
	// if ( ($('.header--show-alt').length) && ($('.search-open').length) || (!$('.tablet').length) ) {
	// 	offset = 265;
	// }
	
	var trigger_height = offset + $('.banner').outerHeight() - parseInt($('.outer-container').css('padding-top'));

    var scroll_height = $('.outer-container').scrollTop();
    // console.log(scroll_height);

    if (scroll_height >= trigger_height) {
        $('.top50-nav').addClass('is--sticky');
    }
    else {
        $('.top50-nav').removeClass('is--sticky');
	}
}

function testScrollDirection() {
	// set up scroll detection test
		
	var lastScrollTop = 0, delta = 5;
	
	$('.outer-container').scroll(function(e){

		if ( (window.matchMedia('(max-width: 680px)').matches) || ($('.tablet').length) ) {

			return;
		}

		var st = $(this).scrollTop();
		
		if(Math.abs(lastScrollTop - st) <= delta)
			return;
		
		if (st > lastScrollTop){
			// going down
			$('body').removeClass('header--show-alt');
			
		} else {
			// going up
			$('body').addClass('header--show-alt');
		}
			lastScrollTop = st;
	});
}

// Socials
// ------------------------------------------------

function initSocial() {

    $('.social-share').share();
}

// Load more News & Videos
// ------------------------------------------------

function initNewsVideosLoadMore() {

    $('.load-more').text('Load more articles');

    $('.load-more').unbind('click').click(function(){

        $('.load-more').text('Loading...').unbind('click').click(function(){
            return false;
        });

        var data = {};
        data.term_id = $('.load-more').attr('data-term_id');
        data.offset = $('.panel--news .news-grid__item').length;
        data.exclude_ids = $('.load-more').attr('data-exclude_ids');
        data.action = 'news-videos-list';

        $.ajax({
            url: ajax_var.url,
            data: data,
            method: 'post',
            dataType: 'json'
        }).done(function (data, status, jqXHR) {

            if (data.status == 'OK') {

                var html = $(data.output);
                html.insertBefore('.panel--news-footer');

                if (!data.has_more_posts) {

                    $('.load-more').remove();
                }

				initWaypoints();
                initNewsVideosLoadMore();
            }
            else {

                $('.load-more').remove();
            }
        });

        return false;
    });
}

// Contact form
// ------------------------------------------------

function initContactForm() {

    if ( $('#contact-form').length ) {

        $form = $('#contact-form');

        $form.validate({
            submitHandler: function (form) {

                var $submit = $form.find('button[type="submit"]');
                var original_button_text = $submit.text();

                $submit
                    .prop('disabled', 'disabled')
                    .css('cursor', 'progress')
                    .text('Submitting Enquiry...');

                var data = $form.serialize();

                $.ajax({
                    url: ajax_var.url,
                    data: data,
                    method: 'post',
                    dataType: 'json'
                }).done(function (data, status, jqXHR) {

                    if (data.status == 'OK') {

                        if (typeof(ga) !== 'undefined') {

                            ga('send', 'event', 'Contact Form', 'submission');
                        }

                        $submit
                            .prop('disabled', true)
                            .addClass('complete')
                            .text('Enquiry Submitted Successfully')
                            .css('cursor', 'default');

                        form.reset();
                    }
                    else {

                        $submit
                            .prop('disabled', false)
                            .text(original_button_text)
                            .css('cursor', 'pointer');
                    }
                });

                return false;
            }
        });
    }
}

// Subscription form
// ------------------------------------------------

var subscriptionSuccessHandler = function (form) {

    var $form = $(form);
    $('.subscription-error, .subscription-success').remove();

    $.getJSON(
        $form.attr('action') + "?callback=?",
        $form.serialize(),
        function (data) {
            if (data.Status === 400) {
                $form.append('<p class="subscription-error">' + data.Message + '</p>');
            } else { // 200
                $form.append('<p class="subscription-success">' + data.Message + '</p>');
                $form[0].reset();
            }
        }
    );
}

function initSubscriptionForm() {

    var form_args = {
        messages: {
            'cm-kyuiduj-kyuiduj': 'Required'
        },
        success: function(label) {
            label.remove();
        },
        submitHandler: subscriptionSuccessHandler
    };

    // $('#subscription-form').validate(form_args);
    // $('#subscription-form-inline').validate(form_args);
}

// Init video modal
// ------------------------------------------------

function initVideoModal() {

	$('.video-modal-trigger').modaal({
		type: 'inline',
		//hide_close: true,
		custom_class: 'modal-video-wrapper'
	});

    if ( (window.matchMedia('(max-width: 680px)').matches) || ($('.tablet').length) || ($('.mobile').length) ) {

        $('.show-video-trigger').hide();
    }

    $('.show-video-trigger').unbind('click').click(function(){

        $(this).fadeOut(1000, function(){

            if ($('.video-iframe--vimeo').length) {

                var iframe = document.querySelector('iframe');
                var player = new Vimeo.Player(iframe);

                player.play();
            }
            else if ($('.video-iframe--youtube').length) {

                var iframe_id = $('iframe:first').attr('id');

                var player = new YT.Player(iframe_id, {
                    events: {
                        'onReady': function(event) {

                            event.target.playVideo();
                        }
                    }
                });
            }
        });

        return false;
    });
}

// Focus subscribe
// ------------------------------------------------

// function initSubscribeFocus() {

// 	$('.link--subscribe').click(function() {
// 		$('#subscribe-email').focus();
// 	});

// }

// Isoopte filter
// ------------------------------------------------

function initNewsFilter() {
	
	if ( $('.page--50-grid').length ) {
		
		var $grid = $('.top50-grid').isotope({
			layoutMode: 'fitRows',
			percentPosition: true,
			filter: $('.top50-nav__anchor .js-is-active').data('filter'),
			isOriginLeft: true
		}).on('layoutComplete', formatFilteredPosition);
		
		var target = $('.top50-nav__anchor a');
		// var offset = ( $('.banner').outerHeight() );  

		function formatFilteredPosition(e, filteredItems) {

			$grid.isotope('getFilteredItemElements').forEach(function(item, i) {

				$('.top50-grid__item_pos', item).text( String(i + 1).padStart(2, '0') );

			});

		}
		
		target.click(function(e) {
			
			var filterValue = $(this).attr('data-filter');
			
			target.removeClass('js-is-active');
			$(this).addClass('js-is-active');
			$grid.isotope({ filter: filterValue });

			setTimeout(function() {
				$('.outer-container').animate({ scrollTop: $('.top50-grid').offset().top + $('.outer-container').scrollTop() - 250 });
				//$('.outer-container').animate({ scrollTop: 0}, 800);
			}, 300);

			if ( $('.is--open').length ) {

				$('.menu-region').removeClass('is--open');
				$('.menu-trigger').removeClass('is--active');

			};
			
			e.preventDefault();
			
		});	

		formatFilteredPosition();
	
	}
}

// Toggle menu
// ------------------------------------------------

function initToggleMenu() {
	
	// Toggle filter options on smaller viewport widths	
	$('.menu-trigger').click(function() {
		$('.menu-region').toggleClass('is--open');
		$(this).toggleClass('is--active');
	});

}


// Hamburger
// ------------------------------------------------

function initMobMenu() {
	
	$('.hamburger').unbind('click').click(function() {
		$(this).toggleClass('hamburger--is-active');
		$('body').toggleClass('menu-open');
	});

	$('.link--close').unbind('click').click(function() {
		$('.hamburger').removeClass('hamburger--is-active');
		$('body').removeClass('menu-open');
	});

}

// Sets class for sticky header styles
// ------------------------------------------------

function initstickyHeader() {
	
	if ( $('.page--front').length ) {

		var target = $('body');
		var scrolltarget = $('.outer-container');

		$(scrolltarget).scroll(function() {

			if ($(scrolltarget).scrollTop() > 200 ) {
				target.addClass('header--hide')
			} else {
				target.removeClass('header--hide')
			}

			if ($(scrolltarget).scrollTop() > 250 ) {
				target.addClass('header--sticky')
			} else {
				target.removeClass('header--sticky')
			}

			if ($(scrolltarget).scrollTop() > 500 ) {
					target.addClass('header--show')
				} else {
					target.removeClass('header--show')
				}
			});
		} 
}

// Toggle Search
// ------------------------------------------------

function initSearchPanel() {
	
	$('.link--search, .link--search-alt').unbind('click').click(function() {
		$('body').toggleClass('search-open');
		$('#search').focus();
	});

	$('.link--close').click(function() {
		$('body').removeClass('search-open');
	});

	document.onkeydown = function(evt) {
		evt = evt || window.event;
		var isEscape = false;
		if ("key" in evt) {
			isEscape = (evt.key == "Escape" || evt.key == "Esc");
		} else {
			isEscape = (evt.keyCode == 27);
		}
		if (isEscape) {
			$('body').removeClass('search-open');
		}
	};

}

// smooth scroll
// ------------------------------------------------

function initSmoothScroll() {

	if ( $('.scroll').length ) {
			
		$('.scroll').unbind('click').click(function(e) {
			e.preventDefault();
			var scroll_target = $(this.hash);
			var scroll_offset = 170;

			if ( $('.page--front').length ) {
				scroll_offset = 50;
			};	
			
			$('.outer-container').animate(
                {
                    scrollTop: scroll_target.offset().top - scroll_offset + $('.outer-container').scrollTop()
                },
                1000,
                'easeInCubic'
            );

			if ( $(this).hasClass('link--subscribe') ) {
				$('#subscribe-email').focus();
			};

		});	
	}

}

$('.special-code').click(function(e){
    e.preventDefault(); // prevent default link behavior
    
    var specialCode = $(this).data('code');
    $(this).text(specialCode);
});


// Hey Slick
// ------------------------------------------------

var slick_sliders = [
	{
	selector: '.panel--logo-gallery',
		args: {
			slidesToShow: 6,
			slidesToScroll: 1,
			infinite: true,
			slide: '.logo-gallery__item',
			speed: 20000,
			dots: false,
			arrows: false,
			draggable: true,
			centerMode: true,
			centerPadding: '0',
			autoplaySpeed: '0',
			cssEase: 'linear',
			autoplay: true,
			responsive: [
				{
			      breakpoint: 1200,
			      settings: {
			        slidesToShow: 3
			      }
			    },
				{
			      breakpoint: 700,
			      settings: {
			        slidesToShow: 2
			      }
			    }
			]
		}
	},
    {
	    selector: '.restaurant-gallery',
		args: {
			slidesToShow: 1,
			slidesToScroll: 1,
			infinite: true,
			slide: '.restaurant-gallery__item',
			dots: false,
			arrows: true,
			fade: false,
			autoplay: true,
			autoplaySpeed: 6000,
			appendArrows: '.restaurant-gallery__nav'
		}
	},
    {
        selector: '.promotion',
        args: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            slide: '.promotion__item',
            dots: false,
            arrows: false,
            fade: true,
            autoplay: true,
            autoplaySpeed: 3000
        }
    },
	{
		selector	: '.card--feature-carousel',
		args		: {
			variableWidth	: true,
			arrows			: false,
			dots			: true
		}
	},
	{
		selector	: '.stays-gallery-wrapper',
		args		: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            slide: '.stays-gallery-img',
            dots: false,
            arrows: true,
            autoplay: true,
            autoplaySpeed: 3000
		}
	}
];

var operators = {
	'<': function(a, b) { return a < b },
	'>': function(a, b) { return a > b },
	'<=': function(a, b) { return a <= b },
	'>=': function(a, b) { return a >= b },
	'==': function(a, b) { return a == b }
};

function slick_init(sliders) {
	
	var window_width = jQuery(window).width();

    // Loop through slider config objects
	jQuery.each(sliders, function(index, value){

		if (jQuery(value.selector).length > 0) {

            // Loop through slider DOM elements
            jQuery(value.selector).each(function(selector_index){

                var updated_args = value.args;

                // Fix for having multiple instances of the same gallery type on the same page

				if(value.args.appendDots) {

					updated_args.appendDots = jQuery(this).siblings(value.args.appendDots);

				}
                // updated_args.appendArrows = jQuery(this).find(value.args.appendArrows);

                if (typeof(value.limit) === 'undefined' || value.limit == null) {

                    if (!jQuery(this).hasClass('slick-initialized')) {
                        jQuery(this).slick(updated_args);
                    }
                }
                else {

                    if (operators[value.operator](window_width, value.limit)) {

                        if (!jQuery(this).hasClass('slick-initialized')) {
                            jQuery(this).slick(updated_args);
                        }

                    }
                    else {

                        if (jQuery(this).hasClass('slick-initialized')) {
                            jQuery(this).slick('unslick');
                        }

                    }

                }

            });

		}

	});

}

// wrap the videoin an iframe
// ------------------------------------------------

function iframeVideo() {
	$('iframe[src*="vimeo.com"],[src*="youtube.com"]').wrap('<div class="iframe-wrapper"></div>');
}

// Is touch: returns true or false
// ------------------------------------------------

function isTouch() {
	return 'ontouchstart' in window || navigator.maxTouchPoints
}


/*
function width_test() {
		
	if (window.matchMedia('(min-width: 1000px)').matches) {
		$('.wide-js').css('display', 'block');
		$('.thin-js').css('display', 'none');
	} else {
		$('.wide-js').css('display', 'none');
		$('.thin-js').css('display', 'block');
	}

}
*/

function megaMenu() {
	//console.log('fire');
	var $navLink = $('.has-dropmenu a');
	$navLink.on('focus blur', function() {
		$(this).parents('li').toggleClass('focussed');
	});
}




/**
 * initMap
 *
 * Renders a Google Map onto the selected jQuery element
 *
 * @date    22/10/19
 * @since   5.8.6
 *
 * @param   jQuery $el The jQuery element.
 * @return  object The map instance.
 */

function initMap( $el ) {

    // Find marker elements within map.
    var $markers = $el.find('.marker');

    // Create gerenic map.
    var mapArgs = {
        zoom        : $el.data('zoom') || 16,
        mapTypeId   : google.maps.MapTypeId.ROADMAP,
        styles      : [ { "featureType": "water", "elementType": "geometry", "stylers": [ { "color": "#e9e9e9" }, { "lightness": 17 } ] }, { "featureType": "landscape", "elementType": "geometry", "stylers": [ { "color": "#f5f5f5" }, { "lightness": 20 } ] }, { "featureType": "road.highway", "elementType": "geometry.fill", "stylers": [ { "color": "#ffffff" }, { "lightness": 17 } ] }, { "featureType": "road.highway", "elementType": "geometry.stroke", "stylers": [ { "color": "#ffffff" }, { "lightness": 29 }, { "weight": 0.2 } ] }, { "featureType": "road.arterial", "elementType": "geometry", "stylers": [ { "color": "#ffffff" }, { "lightness": 18 } ] }, { "featureType": "road.local", "elementType": "geometry", "stylers": [ { "color": "#ffffff" }, { "lightness": 16 } ] }, { "featureType": "poi", "elementType": "geometry", "stylers": [ { "color": "#f5f5f5" }, { "lightness": 21 } ] }, { "featureType": "poi.park", "elementType": "geometry", "stylers": [ { "color": "#dedede" }, { "lightness": 21 } ] }, { "elementType": "labels.text.stroke", "stylers": [ { "visibility": "on" }, { "color": "#ffffff" }, { "lightness": 16 } ] }, { "elementType": "labels.text.fill", "stylers": [ { "saturation": 36 }, { "color": "#333333" }, { "lightness": 40 } ] }, { "elementType": "labels.icon", "stylers": [ { "visibility": "off" } ] }, { "featureType": "transit", "elementType": "geometry", "stylers": [ { "color": "#f2f2f2" }, { "lightness": 19 } ] }, { "featureType": "administrative", "elementType": "geometry.fill", "stylers": [ { "color": "#fefefe" }, { "lightness": 20 } ] }, { "featureType": "administrative", "elementType": "geometry.stroke", "stylers": [ { "color": "#fefefe" }, { "lightness": 17 }, { "weight": 1.2 } ] } ]
    };
    var map = new google.maps.Map( $el[0], mapArgs );

    // Add markers.
    map.markers = [];
    $markers.each(function(){
        initMarker( $(this), map );
    });

    // Center map based on markers.
    centerMap( map );

    // Return map instance.
    return map;
}

/**
 * initMarker
 *
 * Creates a marker for the given jQuery element and map.
 *
 * @date    22/10/19
 * @since   5.8.6
 *
 * @param   jQuery $el The jQuery element.
 * @param   object The map instance.
 * @return  object The marker instance.
 */
function initMarker( $marker, map ) {

    // Get position from marker.
    var lat = $marker.data('lat');
    var lng = $marker.data('lng');
    var latLng = {
        lat: parseFloat( lat ),
        lng: parseFloat( lng )
    };

    // Create marker instance.
    var marker = new google.maps.Marker({
        position    : latLng,
        map         : map,
        icon        : '/wp-content/themes/wagoodfoodguide/img/marker.svg' 
    });

    // Append to reference for later use.
    map.markers.push( marker );

    // If marker contains HTML, add it to an infoWindow.
    if( $marker.html() ){

        // Create info window.
        var infowindow = new google.maps.InfoWindow({
            content: $marker.html()
        });

        // Show info window when marker is clicked.
        google.maps.event.addListener(marker, 'click', function() {
            infowindow.open( map, marker );
        });
    }
}

/**
 * centerMap
 *
 * Centers the map showing all markers in view.
 *
 * @date    22/10/19
 * @since   5.8.6
 *
 * @param   object The map instance.
 * @return  void
 */
function centerMap( map ) {

    // Create map boundaries from all map markers.
    var bounds = new google.maps.LatLngBounds();
    map.markers.forEach(function( marker ){
        bounds.extend({
            lat: marker.position.lat(),
            lng: marker.position.lng()
        });
    });

    // Case: Single marker.
    if( map.markers.length == 1 ){
        map.setCenter( bounds.getCenter() );

    // Case: Multiple markers.
    } else{
        map.fitBounds( bounds );
    }
}


var video = document.getElementById('home-video');
video.addEventListener('loadedmetadata', function() {
	var randomTime = Math.random() * video.duration;
	if (!isNaN(randomTime) && isFinite(randomTime)) {
		video.currentTime = randomTime;
	}
});


$('#home-video').get(0)
.play()
.then(() => {})
.catch((error) => {
    $('#home-video').css('display', 'none');
});